import { RangeWithUnit } from '@hrk/types'

const dict = {
  Stunde: 1,
  Stunden: 1,
  Tag: 24,
  Tage: 24,
  Woche: 168,
  Wochen: 168,
  Monat: 730,
  Monate: 730,
  Jahr: 8_760,
  Jahre: 8_760,
  // The following values are assumptions
  Semester: 900, // One semester 'should' be 900 hours of work
  Modul: 180, // ~ 6 ECTS per module, ~30 hours of work per ECTS
  Module: 180, // ~ 6 ECTS per module, ~30 hours of work per ECTS
  Trimester: 600, // One year 'should' be 1800 hours of work
  Kurs: 90, // one course is hals a module
  Quartal: 450, // One year 'should' be 1800 hours of work
  Quartale: 450, // One year 'should' be 1800 hours of work
}

export function periodToHours(period: RangeWithUnit | undefined): [number, number] {
  if (!period) {
    return [0, 0]
  }
  const hours: [number, number] = [period.from ?? 0, period.to ?? 0]

  if (dict[period.unit]) {
    hours[0] *= dict[period.unit]
    hours[1] *= dict[period.unit]
  }
  return hours
}
