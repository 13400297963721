/**
 * Converts a number representing hours into the ISO 8601 duration format.
 * @param hours The number of hours to convert.
 * @returns A string representing the duration in ISO 8601 format.
 */
export function hoursToISO8601Duration(hours: number | undefined): string {
  if (!hours) {
    return 'PT0S' // If the duration is 0 or undefined
  }
  let remainingHours = hours
  const durationArray: string[] = []

  const years = Math.floor(remainingHours / (24 * 365))
  if (years > 0) {
    durationArray.push(`${years}Y`)
    remainingHours %= 24 * 365
  }

  const months = Math.floor(remainingHours / (24 * 30))
  if (months > 0) {
    durationArray.push(`${months}M`)
    remainingHours %= 24 * 30
  }

  const days = Math.floor(remainingHours / 24)
  if (days > 0) {
    durationArray.push(`${days}D`)
    remainingHours %= 24
  }

  const hoursPart = remainingHours
  const minutesPart = Math.floor(remainingHours / 60)
  const secondsPart = minutesPart % 60

  if (hoursPart > 0 || minutesPart > 0 || secondsPart > 0) {
    durationArray.push(`T`)
  }

  if (hoursPart > 0) {
    durationArray.push(`${hoursPart}H`)
  }

  if (minutesPart > 0) {
    durationArray.push(`${minutesPart}M`)
  }

  if (secondsPart > 0) {
    durationArray.push(`${secondsPart}S`)
  }

  return 'P' + durationArray.join('')
}
